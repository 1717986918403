import React from "react";
import Typer from "../Typer";
import styles from "./style.module.scss";

const WelcomeSection = () => (
  <div className={styles.hideOverflowWrapper}>
    <div className={`inset ${styles.welcomeWrapper}`}>
      <div className={styles.welcomeHeroImage}>
        <picture>
          <img
            src="https://d2uvg1ukogcips.cloudfront.net/next-assets/public/hero.png"
            alt="A conference room table with chairs"
          />
        </picture>
      </div>

      <div className={styles.welcomeHeaderContent}>
        <h2 className={styles.welcomeHeader}>
          SquareFoot.
          <br />
          <div className={styles.typedText}>
            <Typer text="The new way to find office space." />
          </div>
        </h2>
        <a
          href={`${process.env.NEXT_PUBLIC_MIYAGI_LEGACY_URL}/profile/size`}
          className={`btn-primary ${styles.welcomeButton}`}
          data-qa="homepage-onboarding-cta-button"
        >
          Get Started
        </a>
      </div>
    </div>
  </div>
);

export default WelcomeSection;
