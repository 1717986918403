/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { track, analyticsEvents, eventCategories } from "util/analytics";

import styles from "./style.module.scss";

const click = () => {
  track(analyticsEvents.view_case_study, {
    category: eventCategories.homepage,
    label: "Ollie",
  });
};

const TestimonialSection = () => (
  <section className="inset">
    <h2>This can be your future office</h2>
    <div className={styles.testimonialContainer}>
      <blockquote className={styles.testimonialContent}>
        <h3 className={`headline ${styles.testimonialQuotation}`}>
          SquareFoot was able to make a complex process much easier.
        </h3>
        <div className={styles.testimonialTestifier}>
          <strong>Yashoda Clark,</strong> VP of Strategy & Finance, Ollie
        </div>
        <a
          onClick={click}
          target="_blank"
          className={styles.ollieTestimonialLink}
          href="https://www.squarefoot.com/case-study/ollie/"
        >
          Case Study
        </a>
      </blockquote>
      <div className={styles.testimonialImage}>
        <picture>
          <img
            alt="An office lounge area with long leather couch, coffee table, and kitchen in the background"
            src="https://d2uvg1ukogcips.cloudfront.net/next-assets/public/homepage/ollie_office.jpg"
          />
        </picture>
      </div>
    </div>

    <ul className={styles.testimonialLogos}>
      <li>
        <picture>
          <img
            src="https://d2uvg1ukogcips.cloudfront.net/next-assets/public/homepage/clients/casper.png"
            alt="Casper"
          />
        </picture>
      </li>
      <li>
        <picture>
          <img
            src="https://d2uvg1ukogcips.cloudfront.net/next-assets/public/homepage/clients/breather.png"
            alt="Breather"
          />
        </picture>
      </li>
      <li>
        <picture>
          <img
            src="https://d2uvg1ukogcips.cloudfront.net/next-assets/public/homepage/clients/greatist.png"
            alt="Greatist"
          />
        </picture>
      </li>
      <li>
        <picture>
          <img
            src="https://d2uvg1ukogcips.cloudfront.net/next-assets/public/homepage/clients/instacart.png"
            alt="Instacart"
          />
        </picture>
      </li>
      <li>
        <picture>
          <img
            src="https://d2uvg1ukogcips.cloudfront.net/next-assets/public/homepage/clients/bodylabs.png"
            alt="Body Labs"
          />
        </picture>
      </li>
      <li>
        <picture>
          <img
            src="https://d2uvg1ukogcips.cloudfront.net/next-assets/public/homepage/clients/ollie.png"
            alt="Ollie"
          />
        </picture>
      </li>
    </ul>
  </section>
);

export default TestimonialSection;
