import React from "react";
import Image from "next/image";
import PropTypes from "prop-types";
import styles from "./style.module.scss";

const propTypes = {
  topPost: PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: "",
};

const TopPostCard = (props) => {
  const { topPost, className } = props;

  return (
    <article className={`${styles.topPostContainer} ${className || ""}`}>
      <a href={topPost.link}>
        <div className={styles.topPostCardImage}>
          {/* This is a rough approach to using the next/image element but it guards against offensively large images from wordpress */}
          <Image
            alt=""
            loading="lazy"
            src={
              topPost.image ||
              "https://d2uvg1ukogcips.cloudfront.net/next-assets/public/defaultImage.png"
            }
            objectFit="cover"
            layout="fill"
          />
        </div>
        <h3>{topPost.title}</h3>
      </a>
    </article>
  );
};

TopPostCard.propTypes = propTypes;
TopPostCard.defaultProps = defaultProps;

export default TopPostCard;
