import Head from "next/head";
import Router from "next/router";
import { useEffect } from "react";
import { useCurrentUser } from "hooks";
import homepageReplacementPath from "components/HomePage/redirects";
import Layout from "components/layout";
import WelcomeSection from "components/HomePage/WelcomeSection";
import FlexBanner from "components/FlexBanner";
import ValuePropSection from "components/HomePage/ValuePropSection";
import { initializeApollo, addApolloState } from "init/apolloClient";
import TestimonialSection from "components/HomePage/TestimonialSection";
import NotYourOrdinaryBrokerSection from "components/HomePage/NotYourOrdinaryBrokerSection";
import BetterCompanyCultureSection, {
  TOP_POSTS_QUERY,
  TOP_POSTS_QUERY_OPTIONS,
} from "components/HomePage/BetterCompanyCultureSection/index";
import styles from "../styles/Home.module.scss";

export default function Home() {
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    if (currentUser)
      Router.push(
        `${process.env.NEXT_PUBLIC_MIYAGI_LEGACY_URL}${homepageReplacementPath(
          currentUser
        )}`
      );
  }, [currentUser]);

  return (
    <Layout>
      <div className={styles.container}>
        <Head>
          <title>Commercial Real Estate and Office Space | SquareFoot</title>
          <meta name="og:title" content="The new way to find office space." />
          <meta name="og:type" content="website" />
          <meta name="og:url" content="https://www.squarefoot.com" />
          <meta
            name="og:image"
            content="https://d2uvg1ukogcips.cloudfront.net/next-assets/public/homepage/New.png"
          />
          <meta
            property="og:image:secure_url"
            content="https://d2uvg1ukogcips.cloudfront.net/next-assets/public/homepage/New.png"
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta
            name="facebook-domain-verification"
            content="8yp9ictcse7zjdvs32mn4op7yf3t7a"
          />
        </Head>
        <FlexBanner />
        <WelcomeSection />
        <ValuePropSection />
        <TestimonialSection />
        <NotYourOrdinaryBrokerSection />
        <BetterCompanyCultureSection />
      </div>
    </Layout>
  );
}

export const getStaticProps = async () => {
  const apolloClient = initializeApollo();

  // eslint-disable-next-line no-useless-catch
  try {
    await apolloClient.query({
      query: TOP_POSTS_QUERY,
      ...TOP_POSTS_QUERY_OPTIONS,
    });

    return addApolloState(apolloClient, {
      props: {},
    });
  } catch (error) {
    throw error;
  }
};
